<template>
	<b-row>
		<b-col md="6" class="my-1">
			<b-form-group class="mb-0">
				<label class="d-inline-block text-sm-left mr-50">Einträge pro Seite</label>
				<b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
			</b-form-group>
		</b-col>
		<b-col md="6" class="my-1">
			<b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
				<b-input-group size="sm">
					<b-form-input id="filterInput" v-model="filter" type="search" placeholder="Suche.." />
					<b-input-group-append>
						<b-button :disabled="!filter" @click="filter = ''"> Löschen </b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
		</b-col>

		<b-col cols="12">
			<b-table
				striped
				hover
				responsive
				:per-page="perPage"
				:current-page="currentPage"
				:items="requests"
				:fields="fields"
				:filter="filter"
				:filter-included-fields="filterOn"
				@filtered="onFiltered"
				@row-clicked="openRequest"
				:busy="loading"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
			>
				<template #table-busy>
					<div class="text-center my-2 d-flex flex-column align-items-center">
						<b-spinner class="align-middle"></b-spinner>
						<strong class="mt-2">Loading...</strong>
					</div>
				</template>
				<template #cell(createdAt)="data">
					{{ formatDate(data.item.createdAt) }}
					<br />
					<small>{{ formatTime(data.item.createdAt) }}Uhr</small>
				</template>
				<template #cell(expiryDate)="data">
					{{ formatDate(data.item.expiryDate) }}
					<br />
					<small>{{ formatTime(data.item.expiryDate) }}Uhr</small>
				</template>
				<template #cell(customer)="data">
					<span v-if="data.item.customer.firstname">{{ data.item.customer.firstname }} {{ data.item.customer.lastname }}</span>
					<br />
					<!-- <small><a :href="'mailto:' + data.item.customer.email">{{ data.item.customer.email }}</a></small> -->
					<small>{{ data.item.customer.email }}</small>
					<div class="created-by-werc">
						<b-badge variant="primary" v-if="data.item.requestCreatedByAdmin">created by werc</b-badge>
					</div>
				</template>

				<template #cell(status)="data">
					<small
						><b>{{ statusLabels[data.item.status] }}</b></small
					>
				</template>

				<template #cell(assignee)="data">
					<div class="d-flex align-items-center" v-if="data.item.assignedUser">
						<div class="avatar" :style="`background-image: url(${data.item.assignedUser.avatar})`"></div>
						<small
							><b>{{ data.item.assignedUser.firstname }}</b></small
						>
					</div>
				</template>

				<template #cell(configurations)="data"> {{ data.item.configurations.length }} Produkt(e) </template>
			</b-table>
		</b-col>
		<b-col cols="12">
			<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
		</b-col>
	</b-row>
</template>

<script>
import {
	BTable,
	BContainer,
	BPagination,
	BRow,
	BCol,
	BFormGroup,
	BFormSelect,
	BInputGroup,
	BFormInput,
	BInputGroupAppend,
	BButton,
	BSpinner,
	BBadge,
} from 'bootstrap-vue'

import { mapFields } from 'vuex-map-fields'

import dayjs from 'dayjs'

export default {
	name: 'Requests',
	components: {
		BTable,
		BContainer,
		BPagination,
		BRow,
		BCol,
		BFormGroup,
		BFormSelect,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BButton,
		BSpinner,
		BBadge,
	},
	computed: {
		...mapFields('Requests', {
			requests: 'requests',
		}),
	},
	data() {
		return {
			loading: false,
			perPage: 10,
			pageOptions: [10, 20, 50],
			totalRows: 1,
			currentPage: 1,
			filter: null,
			filterOn: ['createdAt', 'projectName', 'customer', 'status'],
			sortBy: 'createdAt',
			sortDesc: true,
			fields: [
				{ label: '#', key: 'publicId', sortable: true },
				{ label: 'Erstellt am', key: 'createdAt', sortable: true },
				{ label: 'Gültig bis', key: 'expiryDate', sortable: true },
				// { label: 'Projekt-Name', key: 'projectName', sortable: true },
				{ label: 'Kunde', key: 'customer', sortable: true },
				{ label: 'Status', key: 'status', sortable: true },
				{ label: 'Produkte', key: 'configurations', sortable: true },
				{ label: 'Assignee', key: 'assignee', sortable: true },
			],
			statusLabels: {
				PENDING: 'In Bearbeitung',
				AWAITING_CONFIRMATION: 'Angebot bereit',
				NEW_CUSTOMER_MESSAGE: 'Anfrage in Bearbeitung',
				AWATING_UPLOAD: 'Upload benötigt',
				AWATING_INFO: 'Information benötigt',
				OFFER_ACCEPTED_AWAITING_PAYMENT: 'Zahlung ausstehend',
				INVOICE_CREATED: 'Rechnung erstellt',
				PAYMENT_RECEIVED: 'Zahlung erhalten',
				PROJECT_REFUSED: 'Projekt abgelehnt',
				CUSTOMER_CANCELLATION: 'Projekt vom Kunden abgesagt',
				PRE_PRODUCTION: 'Produktion in Vorbereitung',
				PRODUCTION: 'In Produktion',
				ON_HOLD: 'Produktion pausiert',
				AWAITING_CUSTOMER_CHECK: 'Kundenfreigabe benötigt',
				POST_PRODUCTION: 'In Nachbearbeitung',
				SHIPPED: 'Versendet',
				COMPLETED: 'Projekt Abgeschlossen',
			},
		}
	},
	created() {
		this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
		this.loading = true
		this.$store
			.dispatch('Requests/fetchRequests')
			.then((res) => {
				this.totalRows = this.requests.length
			})
			.finally(() => (this.loading = false))
	},
	watch: {
		requests(newValue) {
			this.totalRows = newValue.length
			// this triggers the sort
			const tmpSortBy = this.sortBy
			this.sortBy = ''
			this.sortBy = tmpSortBy
		},
	},
	methods: {
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		formatTime(date) {
			return dayjs(date).format('HH:mm')
		},
		openRequest(request) {
			this.$router.push({ name: 'wercbrain-request-details', params: { requestId: request._id } })
		},
	},
}
</script>

<style lang="sass" scoped>
.avatar
	width: 20px
	height: 20px
	border-radius: 50%
	background-size: 100%
	background-position: center
	margin-right: 4px

.created-by-werc
	font-size: 14px
	margin-top: 5px
</style>
